<template>
<div class="col-12 mb-2">
  <b-button variant="info" v-b-modal.editProfile>Edit Profile</b-button>
  <b-modal
    size="sm"
    id="editProfile"
    ref="modal"
    title="EditProfile"
    @show="showModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Name" label-for="name">
        <b-form-input id="name" v-model="profileName" required></b-form-input>
      </b-form-group>

      <b-form-group label="Enable TG Alert" label-for="isTgAlert">
        <b-form-select id="isTgAlert" v-model="isTgAlert" :options="options" required></b-form-select>
      </b-form-group>

      <b-form-group  v-show="isTgAlert" label="TG Alert Interval (seconds)" label-for="tgInterval">
        <b-form-input type="number" id="tgInterval" v-model.number="tgInterval" step="10" min="10" max="3600" ></b-form-input>
      </b-form-group>
      
      <b-form-group  label="Delete Profile" label-for="deleteProfile">
        <b-button variant="danger" id="deleteProfile" @click="handleDelete">Delete Profile</b-button>
      </b-form-group>

    </form>
  </b-modal>
</div>
</template>

<script>
    export default {
        name: 'EditProfile',
        props: ["profile"],
        components: {
        },
        data() {
          return {
            state: false,
            validMsg: "Name is required",
            profileName: '',
            isTgAlert: 0,
            tgInterval: 10,
            newProfileNameState: false,
            newProfileNameValidMsg: "Name is required",
            options: [
              { value: 0, text: 'False' },
              { value: 1, text: 'True' }
            ]

          }
        },
        mounted() {
        },
        methods: {
          checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.newProfileNameState = valid
            return valid
          },
          showModal() {
            this.profileName = this.profile.name;
            this.isTgAlert = this.profile.isTgAlert == undefined ? 0 : this.profile.isTgAlert;
            this.tgInterval = this.profile.tgInterval == undefined ? 10 : this.profile.tgInterval;
          },
          resetModal() {
            this.newProfileNameState = null;
            this.newProfileNameValidMsg = '';

          },
          handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
          },
          handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
              return
            }
            this.$emit('edit', {profileName: this.profileName, isTgAlert: this.isTgAlert, tgInterval: this.tgInterval});
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('editProfile')
            })
          },
          handleDelete() {

            if(confirm('confirm?')) {
              this.$emit('delete');
              // Hide the modal manually
              this.$nextTick(() => {
                this.$bvModal.hide('editProfile')
              })
            }
          },
        },
    }
</script>