<template>
  <div id="app">
    <TopMenu/>
    <AlertBox :alert="alert"/>
      <div class="container-fluid bg-dark" >
        <div class="row bg-dark mb-1" >
          <AddProfile @add="addProfile" v-show='isEditable'/>
          <div class="col-lg-2 col-md-4" >
            <b-form-select :disabled="profiles.length==0" @change="changeCurrentGroupSettings" v-model="currentProfile" :options="profileOptions" class="bg-dark text-light"></b-form-select>
          </div>
          <div class="col-lg-4 col-md-4 ">
            <EditProfile @delete="deleteProfile" @edit="editProfile" v-show='isEditable' :profile="this.profiles.find(profile => profile.id == this.currentProfile)"/>
          </div> 
          <div class="col-lg-4 col-md-1"></div>
          <div class="col-lg-2 col-md-3 text-right">
            <b-button :disabled="profiles.length==0" variant="success" @click="addRowHandler" v-show='isEditable'>Add Symbol</b-button>
          </div> 
        </div>
        <b-table
            id="fullTable"
            :fields="fields"
            :items="currentGroupSettings"
            :sort-by.sync="sortBy"
            small
            bordered
            dark
            responsive
        >
          <template #cell(serverName)="data">
            <b-form-select :disabled="!data.item.isEdit" v-model="data.item.serverId" :options="serverOptions" class="bg-dark text-light"></b-form-select>
          </template>
          <template #cell(symbol)="data">
            <b-form-select :disabled="!data.item.isEdit" v-model="data.item.symbol" 
              :options="getSymbolOptionsById(data.item.serverId)" class="bg-dark text-light"></b-form-select>
          </template>
          <template #cell(edit)="data">
            <b-button @click="editRowHandler(data)" class="ml-1 mt-1 btn-sm">
              <span v-if="!data.item.isEdit"><feather type="settings"></feather></span>
              <span v-else><feather type="check"></feather></span>
            </b-button>
            <b-button @click="onUp(data)" class="ml-1 mt-1 btn-sm">
              <feather type="arrow-up"/>
            </b-button>
            <b-button @click="onDown(data)" class="ml-1 mt-1 btn-sm">
              <feather type="arrow-down"/>
            </b-button>
            <b-button @click="deleteRowHandler(data)" class="ml-1 mt-1  btn-sm">
              <span><feather type="trash-2"></feather></span>
            </b-button>
          </template>
        </b-table>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import TopMenu from '../components/TopMenu.vue'
import AddProfile from '../components/AddProfile.vue'
import EditProfile from '../components/EditProfile.vue'
import AlertBox from '../components/AlertBox.vue'

export default {
  name: 'App',
  components: {
    TopMenu,
    AddProfile,
    EditProfile,
    AlertBox
},
  data() {
      return {
          isEditable: true,
          profiles: [],
          symbolSettings : [],
          serverRecords: [],
          groupRecords: [],
          currentProfile :0,
          sortBy: "id",
          fields: [
            {
                key:"id",
                sortable: true,
            },
            {
                key:"serverName",
            },
            {
                key:"symbol",
            },
            {
                key:"edit",
            }
          ],
          currentGroupSettings: [],
          alert: {
            time: '',
            text: '',
            variant: 'info'
          }
      }
  },
  async mounted() {
    this.filterUser();
    await this.fetchServerRecords();
    await this.fetchSymbolSettings();
    await this.fetchProfiles();
    await this.fetchGroupRecords();
    this.currentProfile = this.profiles.length ? this.profiles.at(0).id : 0;
    this.changeCurrentGroupSettings();
  },
  computed: {
    profileOptions() {
      return this.profiles.map(profile => ( {value:profile.id, text: profile.name} ));
    },
    serverOptions() {
      return this.serverRecords.map(server => ( {value: server.id, text: server.serverName} ));
    },
    symbolOptions() {
      return this.serverRecords.map( serverRecord => ({serverId: serverRecord.id, 
        options: this.symbolSettings.filter(s=>s.serverId == serverRecord.id).map(s=>({value: s.symbol, text: s.symbol}))
      }));
    }
  },
  methods: {
    filterUser() {
      if(this.$store.getters.getSession.user.name != 'admin') {
        this.fields = this.fields.filter(field => field.key != 'edit');
        this.isEditable = false;
      }
    },
    async fetchGroupRecords() {
      await this.$store.dispatch('fetchGroupRecords').then((res) => {
        if(res.status == 200) {
          this.groupRecords = this.$store.getters.getGroupRecords;
        } else this.alert = {text:'failed to get group records, reson=' + res.data, variant: 'danger'};
      });
    },
    async fetchServerRecords() {
      await this.$store.dispatch('fetchServerRecords').then((res) => {
        if(res.status == 200) {
          this.serverRecords = this.$store.getters.getServerRecords;
        } else this.alert = {text:'failed to get server records, reson=' + res.data, variant: 'danger'};
      });
    },
    async fetchSymbolSettings() {
      await this.$store.dispatch('fetchSymbolSettings').then((res) => {
        if(res.status == 200) {
          this.symbolSettings = this.$store.getters.getSymbolSettings;
        } else this.alert = {text:'failed to get Symbol Settings, reson=' + res.data, variant: 'danger'};
      });
    },
    async fetchProfiles() {
      await this.$store.dispatch('fetchProfiles').then((res) => {
        if(res.status == 200) {
          this.profiles = this.$store.getters.getProfiles;
        } else this.alert = {text:'failed to get Profiles, reson=' + res.data, variant: 'danger'};
      });
    },
    updateGroupRecords() {
        this.$store.dispatch('updateGroupRecords', this.groupRecords).then((res) => {
          if(res.status == 200) {
            this.alert = {text:'Group Records successfully updated', variant: 'success'};
          } else this.alert = {text:res.data, variant: 'danger'};
        });
    },
    updateProfiles() {
        this.$store.dispatch('updateProfiles', this.profiles).then((res) => {
          if(res.status == 200) {
            this.alert = {text:'Profiles successfully updated', variant: 'success'};
          } else this.alert = {text:res.data, variant: 'danger'};
        });
    },
    onUp(data) {
      let newId = data.item.id-1;
      let upperItemFound = this.currentGroupSettings.find(setting=>setting.id == newId);
      if(upperItemFound!= undefined) {
        upperItemFound.id = data.item.id;
        data.item.id = newId;
        this.groupRecords.find(group=>group.profileId == this.currentProfile).settings 
          = this.currentGroupSettings.sort((a,b)=>a.id-b.id).map(setting => ({serverId: setting.serverId, symbol: setting.symbol}));
        this.updateGroupRecords();      
      }
    },
    onDown(data) {
      let newId = data.item.id+1;
      let lowerItemFound = this.currentGroupSettings.find(setting=>setting.id == newId);
      if(lowerItemFound != undefined) {
        lowerItemFound.id = data.item.id
        data.item.id = newId;
        this.groupRecords.find(group=>group.profileId == this.currentProfile).settings 
          = this.currentGroupSettings.sort((a,b)=>a.id-b.id).map(setting => ({serverId: setting.serverId, symbol: setting.symbol}));
        this.updateGroupRecords();      
      }

    },
    editRowHandler(data) {
      //console.log(data);
      data.item.isEdit = !data.item.isEdit;
      if(!data.item.isEdit) {
        this.groupRecords.find(group=>group.profileId == this.currentProfile).settings 
          = this.currentGroupSettings.map(setting => ({serverId: setting.serverId, symbol: setting.symbol}));
        this.updateGroupRecords();
      }
    },
    deleteRowHandler(data) {
      const indexOfObject = this.currentGroupSettings.findIndex(object => 
      { return object.id === data.item.id;});
      this.currentGroupSettings.splice(indexOfObject, 1);
      this.groupRecords.find(group=>group.profileId == this.currentProfile).settings 
        = this.currentGroupSettings.map(setting => ({serverId: setting.serverId, symbol: setting.symbol}));
      this.updateGroupRecords();
    },
    addRowHandler() {
      let newRow = this.fields.reduce((a, c) => ({ ...a, [c.key]: null }), {});
      newRow.id = this.currentGroupSettings.reduce((a,c) => c.id > a ? c.id : a, 0) + 1;
      newRow.isEdit = true;
      this.currentGroupSettings.unshift(newRow);
    },
    findServerName(serverId) {
      let found = this.serverRecords.find(server=> server.id == serverId);
      if(found != undefined)
      {
        return found.serverName;
      }
      else return serverId;
    },
    getSymbolOptionsById(serverId) {
      return this.symbolOptions.find(s=>s.serverId == serverId) ? 
        this.symbolOptions.find(s=>s.serverId == serverId).options : [];
    },
    changeCurrentGroupSettings() {
      let groupSetting = this.groupRecords.find(record => record.profileId == this.currentProfile);
      if(groupSetting != undefined)
      {
        var id=1;
        this.currentGroupSettings = groupSetting.settings.map(
          setting => ({...setting, id: id++,isEdit: false}));
      }
      else {
        this.currentGroupSettings = [];
      }
    },
    editProfile(editProfileSettings) {
      //let profile = this.profiles.find(profile => profile.id == this.currentProfile);
      this.profiles.find(profile => profile.id == this.currentProfile).name = editProfileSettings.profileName;
      this.profiles.find(profile => profile.id == this.currentProfile).isTgAlert = editProfileSettings.isTgAlert;
      this.profiles.find(profile => profile.id == this.currentProfile).tgInterval = editProfileSettings.tgInterval;
      this.updateProfiles();
    },
    addProfile(newProfileSettings) {
      let newId = this.profiles.reduce((a,c) => c.id > a ? c.id : a, 0) + 1;
      //console.log(newProfileSettings);
      this.profiles.push({ id: newId, name: newProfileSettings.newProfileName, isTgAlert: newProfileSettings.isTgAlert, tgInterval: newProfileSettings.tgInterval});
      this.currentProfile = newId;
      this.groupRecords.push( {profileId: newId, settings:[]} );
      this.changeCurrentGroupSettings();
      this.updateProfiles();
      this.updateGroupRecords();
    },
    deleteProfile() {
      this.profiles = this.profiles.filter(profile => profile.id != this.currentProfile);
      this.groupRecords = this.groupRecords.filter(record => record.profileId != this.currentProfile);
      this.currentProfile = this.profiles.length > 0 ? this.profiles.at(0).id : 0;      
      this.changeCurrentGroupSettings();
      this.updateProfiles();
      this.updateGroupRecords();
    },
  },
  setup () {
    console.log("I am in setup!!!")
  }
}
</script>

<style>
  @import '../assets/styles/global.css';
</style>